var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    _vm._b(
      { staticClass: "d-flex", attrs: { "fill-height": "", column: "" } },
      "v-layout",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-flex",
        {
          ref: "messagesSection",
          staticClass: "messages-section overflow-y-auto px-5",
          attrs: { grow: "" },
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "pa-10",
              attrs: {
                "align-center": "",
                "justify-center": "",
                "fill-height": _vm.assistantThreadMessages.list.length == 0,
                "d-flex": "",
                column: "",
              },
            },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v("fa-light fa-user-hair-buns"),
              ]),
              _c("span", {
                staticClass: "mt-2 text-center",
                domProps: { innerHTML: _vm._s(_vm.$t("aiChat.introduction")) },
              }),
            ],
            1
          ),
          _vm.assistantThreadMessages.list.length > 0
            ? [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  _vm._l(
                    _vm.assistantThreadMessages.list,
                    function (message, messageIndex) {
                      return _c(
                        "v-row",
                        { key: messageIndex },
                        [
                          message.object && message.object.content
                            ? [
                                message.object.role === "User"
                                  ? [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex justify-end px-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "highlighted-background my-0",
                                              attrs: {
                                                flat: "",
                                                rounded: "xl",
                                                dense: "",
                                                "min-width": "50%",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                message.object.content,
                                                function (
                                                  content,
                                                  contentIndex
                                                ) {
                                                  return _c(
                                                    "v-card-text",
                                                    {
                                                      key: contentIndex,
                                                      staticClass:
                                                        "pb-0 pt-2 px-5",
                                                    },
                                                    [
                                                      content.text
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  content.text
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "text-caption font-weight-bold pt-0 pb-2  px-5",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("aiChat.you")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                message.object.role === "Assistant"
                                  ? [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex px-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                flat: "",
                                                "min-width": "50%",
                                                "max-width": "85%",
                                                color: "transparent",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                message.object.content,
                                                function (
                                                  content,
                                                  contentIndex
                                                ) {
                                                  return _c(
                                                    "v-card-text",
                                                    {
                                                      key: contentIndex,
                                                      staticClass: "pa-0",
                                                    },
                                                    [
                                                      content.text
                                                        ? _c("Markdown", {
                                                            staticClass:
                                                              "body-2 assistant-response",
                                                            attrs: {
                                                              src: content.text,
                                                              writing:
                                                                message.object &&
                                                                message.object
                                                                  .status ===
                                                                  "in_progress",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "text-caption font-weight-bold pa-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("aiChat.brandy")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }
                  ),
                  1
                ),
              ]
            : _vm._e(),
          _c("div", { ref: "endOfMessages" }),
        ],
        2
      ),
      _c(
        "v-flex",
        { staticClass: "input-section", attrs: { shrink: "" } },
        [
          _c("v-text-field", {
            staticClass: "my-1 mx-5",
            attrs: {
              disabled:
                _vm.assistantThread.isBusy ||
                _vm.newAssistantThreadMessage.isBusy,
              "background-color": "#E2E2EE",
              placeholder: _vm.$t("aiChat.inputPlaceholder"),
              filled: "",
              rounded: "",
              "hide-details": true,
              "append-icon": "fa-solid fa-circle-arrow-up",
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.postUserTextMessage.apply(null, arguments)
              },
              "click:append": _vm.postUserTextMessage,
            },
            model: {
              value: _vm.userTextMessage,
              callback: function ($$v) {
                _vm.userTextMessage = $$v
              },
              expression: "userTextMessage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }